<template>
  <div>
    <div v-if="currentBlock" class="link-list">
      <div class="flex items-center h-full px-4 py-2 bg-secondary-600 max-h-11">
        <div class="links-title">
          {{ $t('editor.admin.linkList.title', { text: currentBlock.text }) }}
        </div>
        <button class="ml-8" @click="resetCurrentBlock">
          <svg-icon class="text-white min-w-6 min-h-6" name="chevron-down" />
        </button>
      </div>
    </div>
    <div v-if="currentBlock" class="px-5 py-3 rounded-b shadow-md bg-gray-50">
      <div v-for="link in currentBlock.links" :key="link.id" class="mt-2">
        <a
          :href="getLinkHref(link)"
          class="text-secondary-600"
          target="_blank"
          :[link.linkType]="true"
        >
          {{ getLinkText(link) }}
        </a>
        <span
          v-if="link.destination"
          class="block overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ link.destination.documentTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import UserEventBus from '@/helpers/UserEventBus';
import { getLinkHref } from '@/helpers/links';

export default {
  data() {
    return {
      currentBlock: null,
    };
  },
  mounted() {
    UserEventBus.$on('setCurrentBlock', this.setCurrentBlock);
  },
  beforeDestroy() {
    UserEventBus.$off('setCurrentBlock', this.setCurrentBlock);
  },
  methods: {
    setCurrentBlock(block) {
      this.currentBlock = block;
    },
    getLinkHref(link) {
      return getLinkHref(link);
    },
    getLinkText(link) {
      if (link.destination) {
        return link.destination.title;
      }
      if (link.linkedDocument) {
        return link.linkedDocument.title;
      }
      return link.text;
    },
    resetCurrentBlock() {
      this.currentBlock = null;
      UserEventBus.$emit('setCurrentBlock', null);
    },
  },
};
</script>

<style lang="postcss" scoped>
.links-title {
  @apply w-full;
  @apply overflow-hidden font-sansbold text-white whitespace-nowrap overflow-ellipsis;
}
</style>
