const linkRegex = new RegExp(/\[link=(\d*)\](.+?)\[\/link]/gm);

const replaceLinksToHTMLLinks = (text, links) => {
  if (!text) {
    return text;
  }

  const matches = text.match(linkRegex);
  // If there is no link in the text we can just return the text
  if (!matches) {
    return text;
  }

  // Replace all [link][/link] with a real html link
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const groups = linkRegex.exec(match);
    linkRegex.lastIndex = 0;
    if (!groups) {
      continue;
    }
    const linkId = parseInt(groups[1]);
    const linkText = groups[2];

    const link = links.find((link) => link.id === linkId);

    if (!link) {
      continue;
    }

    let linkHref = '';

    if (link.url) {
      linkHref = link.url;
    } else if (link.linkedDocument) {
      linkHref = `/document/${link.linkedDocument.id}`;
    } else if (link.destination) {
      linkHref = `/document/${link.destination.documentId}/${link.destination.id}`;
    } else {
      linkHref = `/link/${link.id}/`
    }

    const htmlLink = `<a data-link-id="${linkId}" href="${linkHref}" ${link.linkType} target="_blank">${linkText}</a>`;

    text = text.replace(match, htmlLink);
  }

  return text;
};

const replaceHtmlLinksWithLinks = (text) => {
  if (!text) {
    return text;
  }

  let textWithAsaLinks = '';

  const dom = new DOMParser().parseFromString(text, 'text/html');

  const handleChildNode = (childNode) => {
    if (childNode.nodeName === '#text') {
      textWithAsaLinks += childNode.textContent;
    } else if (childNode.nodeName !== 'A') {
      if (childNode.nodeName === 'IMG') {
        textWithAsaLinks += childNode.outerHTML;
        return;
      }

      textWithAsaLinks += `<${childNode.nodeName.toLowerCase()}>`;
      childNode.childNodes.forEach((newChildNode) => {
        handleChildNode(newChildNode);
      });
      if (childNode.nodeName !== 'BR') {
        textWithAsaLinks += `</${childNode.nodeName.toLowerCase()}>`;
      }
    } else {
      textWithAsaLinks += `[link=${childNode.getAttribute('data-link-id')}]${
        childNode.textContent
      }[/link]`;
    }
  };

  dom.body.childNodes.forEach((childNode) => {
    handleChildNode(childNode);
  });

  return textWithAsaLinks;
};

const textWithoutLinks = (text) => {
  const matches = text.match(linkRegex);

  if (!matches) {
    return text;
  }

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const groups = linkRegex.exec(match);
    linkRegex.lastIndex = 0;
    if (!groups) {
      continue;
    }

    const linkText = groups[2];

    text = text.replace(match, linkText);
  }

  return text;
};

const getLinkHref = (link) => {
  if (link.destination) {
    return `/document/${link.destination.documentId}/${link.destination.id}`;
  }
  if (link.linkedDocument) {
    return `/document/${link.linkedDocument.id}`;
  }
  return link.url;
};

export {
  replaceLinksToHTMLLinks,
  textWithoutLinks,
  replaceHtmlLinksWithLinks,
  getLinkHref,
};
