<template>
  <div
    class="relative flex w-full print-block"
    :class="source.htmlElementReference"
  >
    <div
      v-if="!isImageBlock"
      class="inline-block leading-normal align-top"
      :style="blockStyles"
    >
      <em v-if="isLetterBlock || isDigitBlock">{{ source.counter }}.</em>
      <sup v-if="isNumberedParagraph" class="mr-1">{{ source.counter }}</sup>
      <span v-html="text"></span>
    </div>
    <div v-else>
      <img :src="source.imagePath" alt="Bild" />
    </div>
  </div>
</template>

<script>
import blockMixin from '@/components/blockMixin.js';

export default {
  name: 'DocumentBlock',
  mixins: [blockMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
};
</script>
