<template>
  <div class="flex items-center">
    <button
      class="w-[2rem] h-[2rem] text-secondary-600 pt-1"
      :title="$t('tooltips.toggleHeader')"
      @click="toggleHeader"
    >
      <svg
        viewBox="0 0 80 80"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="4" width="78" height="8" rx="2" fill="currentColor" />
        <path
          class="w-full h-full collapse-transform"
          :class="{ 'collapsed-header': isHeaderCollapsed }"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.4386 24.5931L55.4037 38.5784C55.5953 38.7667 55.7456 38.9897 55.8474 39.2333C55.9498 39.4786 56.0012 39.7406 56 40.0037C55.9993 40.3939 55.8828 40.7777 55.6614 41.1063C55.4392 41.4362 55.12 41.697 54.7409 41.8502C54.3616 42.0036 53.9429 42.0407 53.54 41.9546C53.141 41.8694 52.7816 41.6686 52.5046 41.3838L39.9991 28.8466L27.4812 41.3894C27.2945 41.5766 27.0708 41.7272 26.8216 41.8307C26.5721 41.9344 26.3038 41.9879 26.0324 41.988C25.7612 41.988 25.4928 41.9347 25.2431 41.8311C24.994 41.7278 24.77 41.5772 24.5829 41.3898C24.3959 41.2024 24.2493 40.9818 24.15 40.7413C24.0506 40.5008 23.9999 40.2442 24 39.9859C24.0001 39.7276 24.0509 39.471 24.1504 39.2306C24.2498 38.9903 24.3966 38.7696 24.5837 38.5824L38.5506 24.5952L38.5526 24.5931C38.9302 24.2172 39.4482 24 39.9956 24C40.543 24 41.0611 24.2172 41.4386 24.5931Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHeaderCollapsed: false,
    };
  },
  methods: {
    toggleHeader() {
      this.isHeaderCollapsed = !this.isHeaderCollapsed;
      this.$emit('setHeaderCollapsed', this.isHeaderCollapsed);
    },
  },
};
</script>

<style lang="postcss" scoped>
.collapsed-header {
  transform: rotate(180deg);
}

.collapse-transform {
  transition: all 1s ease;
  transform-origin: center;
}
</style>
