<template>
  <div class="custom-dropdown" tabindex="-1" @blur="closeDropdown">
    <div class="custom-dropdown--selectedOption" @click="toggleDropdown">
      {{ getText(selectedOption) }}
    </div>
    <ol class="custom-dropdown--options" :class="{ hidden: !isOpen }">
      <li
        v-for="option in options"
        :key="`block-${option.id}`"
        :class="{
          'custom-dropdown--selected': option.id === selectedOption.id,
        }"
        @click="selectOption(option)"
      >
        {{ getText(option) }}
      </li>
    </ol>
  </div>
</template>

<script>
import { textWithoutLinks } from '@/helpers/links';
import { stripHTML } from '@/helpers/format';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    option: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: this.option || this.options[0],
    };
  },
  watch: {
    option(newOption) {
      if (newOption) {
        this.selectedOption = newOption;
      }
    },
  },
  async mounted() {
    const blockId = this.$route.params.blockId;
    if (blockId) {
      this.selectedOption = this.options.find((b) => b.id === blockId);
      this.$emit('input', this.selectedOption);
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.closeDropdown();
      this.$emit('input', this.selectedOption);
    },
    getText(option) {
      return stripHTML(textWithoutLinks(option.text));
    },
  },
};
</script>
