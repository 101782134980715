<template>
  <document-layout class="frontend">
    <div>
      <document-detail :key="$route.params.id + $route.query.lang" />
    </div>
  </document-layout>
</template>

<script>
import DocumentDetail from '@/components/user/DocumentDetail';

export default {
  components: {
    DocumentDetail,
  },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>
