import { replaceLinksToHTMLLinks } from '@/helpers/links';
const isStickyBlock = (block) => {
  switch (block.htmlElementReference) {
    case 'heading_2':
    case 'heading_3':
    case 'part':
    case 'chapter':
    case 'section':
    case 'heading_4':
    case 'heading_5':
    case 'heading_6':
    case 'article':
    case 'title':
      return true;
    default:
      return false;
  }
};

const render = (block, fragment, isLastBlock) => {
  let isSticky = isStickyBlock(block);
  let classes = block.htmlElementReference;

  let html = `<div class="relative flex w-full pl-24 xl:pl-32 ${classes}" ${
    isSticky ? 'sticky' : ''
  } data-block-id="${block.id}">`;

  if (block.htmlElementReference !== 'image') {
    const isBlockWithCounter =
      block.htmlElementReference === 'letter' ||
      block.htmlElementReference === 'digit' ||
      block.htmlElementReference === 'list';

    const style = isBlockWithCounter
      ? `style="margin-left: ${24 * block.listDeepness}px"`
      : '';

    let counterElement = '';

    if (
      block.htmlElementReference === 'letter' ||
      block.htmlElementReference === 'digit'
    ) {
      counterElement = `<em>${block.counter}</em>`;
    }

    if (block.htmlElementReference === 'num_paragraph') {
      counterElement = `<sup class="mr-1">${block.counter}</sup>`;
    }

    html += `<div class="inline-block leading-normal align-top" ${style}>
          ${counterElement}
          <span>${replaceLinksToHTMLLinks(block.text, block.links)}</span>
        </div>`;
  } else {
    html += `<img src="${block.imagePath}" alt="Bild" />`;
  }

  if (isLastBlock) {
    html += '<div class="w-full h-screen" />';
  }

  html += '</div>';

  const element = document.createElement('template');
  element.innerHTML = html;

  fragment.appendChild(element.content.firstChild);
};

export { render };
