<template>
  <div>
    <div class="print-header">
      <div v-if="document.srNumber">{{ document.srNumber }}</div>
      <div class="font-sansbold">{{ document.title }}</div>
      <div>{{ document.creator.name }}, {{ validFrom }}</div>
    </div>
    <div class="print-footer">
      <div class="font-sansbold">
        {{ $t('user.document.print.date', { date: currentDate }) }}
      </div>
      <div class="flex justify-between">
        <span>
          {{ $t('user.document.print.footer') }}
        </span>
      </div>
    </div>
    <table class="text-[10pt]">
      <thead>
        <tr>
          <td>
            <div class="page-header-space" />
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <document-block-printing
              v-for="block in blocksToPrint"
              :key="block.id"
              :source="block"
            />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space" />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import DocumentBlockPrinting from '@/components/user/DocumentBlockPrinting.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

export default {
  components: {
    DocumentBlockPrinting,
  },
  props: {
    blocksToPrint: {
      type: Array,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    validFrom() {
      let from = dayjs(this.document.validFrom)
        .locale(this.$i18n.locale)
        .format('DD.MM.YYYY');

      // If the document is out of order we show the daterange the document was valid in.
      if (this.document.validityState === 'is_out_of_order') {
        let to = dayjs(this.document.validTo)
          .locale(this.$i18n.locale)
          .format('DD.MM.YYYY');
        return `${from} - ${to}`;
      }

      return from;
    },
    currentDate() {
      return dayjs().format('DD.MM.YYYY');
    },
  },
};
</script>

<style lang="postcss">
@page {
  size: auto;
  margin: 0px 36px 0px 72px;
}

.print-footer {
  @apply fixed bottom-0 w-full;
  @apply pt-2 pb-8;
  @apply border-t-2 border-gray-800;
  font-size: 8pt;
}

.print-header {
  @apply fixed top-0 w-full;
  @apply pb-2 pt-8;
  @apply border-b-2 border-gray-800;
  font-size: 8pt;
}

.page-header-space {
  height: 90px;
}

.page-footer-space {
  height: 100px;
}

@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}

.article {
  font-size: 12pt !important;
}

.print-block {
  page-break-inside: avoid;
}
</style>

<style lang="postcss" scoped>
td {
  @apply !p-0 !border-0;
}

thead {
  @apply !border-0;
}
</style>
