<template>
  <div>
    <v-popover
      ref="popover"
      popover-class="context-menu"
      placement="left-start"
    >
      <button>
        <svg-icon name="menu" class="text-secondary-600 svg-icon--lg" />
      </button>
      <template slot="popover">
        <div>
          <div class="context-menu--item">
            <button disabled>
              {{ $t('admin.documentList.contextMenu.choose') }}
            </button>
          </div>
          <div class="context-menu--item">
            <button @click="print">
              <svg-icon name="print" class="inline svg-icon--lg" />
              {{ $t('user.document.headingActions.print') }}
            </button>
          </div>
          <div class="context-menu--item">
            <button @click="changeFavoriteStatus()">
              <svg-icon name="favorite" class="inline svg-icon--lg" />
              <span v-if="!favorite">{{ $t('favorite.add') }}</span>
              <span v-else>{{ $t('favorite.remove') }}</span>
            </button>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import UserEventBus from '@/helpers/UserEventBus';
import { addFavorite, deleteFavorite } from '@/services/Favorite.service';
import { mapState } from 'vuex';

export default {
  name: 'ContextMenu',
  props: {
    menuButtonClasses: {
      type: String,
      default: '',
    },
    currentHeading: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('Favorite', ['favorites']),
    favorite() {
      return this.favorites[this.$i18n.locale].find(
        (f) => f.lexBlock && f.lexBlock.id === this.currentHeading.id
      );
    },
  },
  methods: {
    print() {
      UserEventBus.$emit('print');
    },
    async changeFavoriteStatus() {
      if (!this.favorite) {
        await addFavorite({
          lexBlock: this.currentHeading.id,
        });
      } else {
        await deleteFavorite(this.favorite.id);
      }
      await this.$store.dispatch('Favorite/loadFavorites');
    },
  },
};
</script>

<style lang="postcss">
.context-menu {
  @apply bg-gray-50;
  @apply w-[320px];
  margin-top: 10px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 3px 3px;
}

.context-menu--item {
  @apply border-b border-dotted text-secondary-600 px-2 py-1 cursor-pointer;
  & a {
    @apply w-full;
  }

  & button {
    @apply w-full text-left;
  }

  & svg {
    @apply mr-3;
  }
}

.context-menu--item:first-of-type {
  @apply border-none;
}

.context-menu--item:not(:first-of-type):hover {
  @apply bg-secondary-600 text-white;

  & a {
    @apply text-white;
  }
}

.context-menu--item button[disabled] {
  @apply text-gray-400 cursor-not-allowed;
}
</style>
