<template>
  <div class="custom-dropdown" tabindex="-1" @blur="closeDropdown">
    <div
      class="flex items-center custom-dropdown--selectedOption"
      @click="toggleDropdown"
    >
      <document-state
        :document="selectedVersion"
        container-classes="!mt-0 mr-4"
      />
      {{ documentVersionLabel(selectedVersion) }}
    </div>
    <ol class="custom-dropdown--options" :class="{ hidden: !isOpen }">
      <li
        v-for="version in versions"
        :key="`block-${version.id}`"
        :class="{
          'custom-dropdown--selected': version.id === selectedVersion.id,
        }"
        class="flex justify-content"
        @click="selectVersion(version)"
      >
        <document-state :document="version" container-classes="mr-4" />
        {{ documentVersionLabel(version) }}
      </li>
    </ol>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DocumentState from '@/components/DocumentState';

export default {
  components: {
    DocumentState,
  },
  props: {
    versions: {
      type: Array,
      required: true,
    },
    selectedVersion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectVersion(version) {
      if (parseInt(this.$route.params.id) !== version.id) {
        this.$router.push({
          name: 'documentDetail',
          params: { id: version.id },
        });
      }
    },
    documentVersionLabel(version) {
      return `${this.$t('documentVersions.versionOf')} ${dayjs(
        version.validFrom
      ).format('DD.MM.YYYY')}`;
    },
  },
};
</script>

<style lang="postcss" scoped>
.custom-dropdown {
  @apply bg-secondary-800;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.2552 1.24598C23.2551 1.0965 23.2104 0.950413 23.1268 0.826515C23.0431 0.702616 22.9244 0.606489 22.7857 0.550559C22.6471 0.494628 22.4949 0.481415 22.3487 0.512582C22.2025 0.54375 22.0689 0.617957 21.9652 0.725562L11.9996 10.6966L2.02854 0.725562C1.95876 0.655704 1.8759 0.600233 1.78469 0.56241C1.69348 0.524587 1.59571 0.505083 1.49696 0.505056C1.39822 0.50503 1.30044 0.524463 1.20921 0.562237C1.11798 0.600012 1.03509 0.655395 0.96527 0.725215C0.895449 0.795036 0.84007 0.877934 0.802295 0.969164C0.76452 1.06039 0.745091 1.15823 0.745117 1.25697C0.745143 1.35571 0.764624 1.45348 0.802447 1.54469C0.84027 1.6359 0.895694 1.71877 0.965551 1.78855L11.4681 12.2856C11.6087 12.4253 11.7987 12.5036 11.9969 12.5036C12.195 12.5036 12.385 12.4253 12.5256 12.2856L23.0282 1.78855C23.1006 1.71808 23.1581 1.63369 23.1971 1.54045C23.2361 1.44722 23.2559 1.34705 23.2552 1.24598H23.2552Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  & .custom-dropdown--selectedOption {
    @apply text-white;
  }
}
</style>
