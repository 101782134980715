<template>
  <div v-if="isOpen" class="notification-container">
    <div class="flex items-center justify-between w-full responsive-container">
      <div class="flex items-center">
        <h2 class="mr-2 text-lg">
          {{ $t('document.protectedNotification.title') }}:
        </h2>
        <p class="text-lg">
          {{ $t('document.protectedNotification.body') }}
        </p>
      </div>
      <button @click="hideNotification">
        <svg-icon name="close" class="svg-icon--lg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    hideNotification() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="postcss" scoped>
.notification-container {
  @apply flex items-center justify-between;
  @apply py-4 px-10 bg-primary-50 4xl:px-0;
}
</style>
