import { saveBlock } from '@/services/document';
import { updateBlock, repealBlock } from '@/services/block';
import EditorEventBus from '@/helpers/EditorEventBus';
import i18n from '@/i18n';
import {
  createTreeFromList,
  flattenTheTree,
  getLetterFromCounter,
} from './blockList';
import { replaceHtmlLinksWithLinks } from './links';

const AUTOSAVE_TIMEOUT = 2000;
const TABLE_OF_CONTENTS_TYPES = [
  'chapter',
  'part',
  'section',
  'title',
  'article',
];

export default class Blocks {
  constructor(blocks) {
    this.blocks = blocks;
    this.saveTimeout;
  }

  findBlock(blockId) {
    return this.blocks.find((block) => block.id === blockId);
  }

  /**
   * Saves all dirty blocks
   */
  async save() {
    const dirtyBlocks = this.blocks.filter((block) => block.isDirty);

    const promises = [];

    for (let i = 0; i < dirtyBlocks.length; i++) {
      const block = dirtyBlocks[i];
      block.isDirty = false;
      block.text = replaceHtmlLinksWithLinks(block.text);
      promises.push(saveBlock(block));
    }

    const results = await Promise.allSettled(promises);

    // Check if every promise is fulfilled
    if (results.every((result) => result.status === 'fulfilled')) {
      EditorEventBus.$emit(
        'notify',
        i18n.t('notifications.saveBlockSuccess'),
        'success'
      );
    } else {
      EditorEventBus.$emit(
        'notify',
        i18n.t('notifications.updateBlockError'),
        'error'
      );
    }

    this.saveTimeout = 0;
  }

  /**
   * Update the given property on the block
   * @param {*} blockId The block to update
   * @param {*} propertyName The property that should be updated
   * @param {*} value The new value for the property
   * @returns Nothing
   */
  updateBlockPropertyName(blockId, propertyName, value) {
    const block = this.findBlock(blockId);
    if (!block) {
      return;
    }

    block[propertyName] = value;

    // Links are special because they are only linked to the block and the block doesnt needs to be saved after a link change
    if (propertyName === 'links') {
      return;
    }

    block.isDirty = true;

    // Clear current save timeout
    window.clearTimeout(this.saveTimeout);
    // Create a new save timeout
    this.saveTimeout = setTimeout(() => this.save(), AUTOSAVE_TIMEOUT);
  }

  updateBlockFormat(blockId, newFormat) {
    this.updateBlockPropertyName(blockId, 'htmlElementReference', newFormat);
    // Reload all blocks
    this.blocks = flattenTheTree(createTreeFromList(this.blocks)[0]);
  }

  /**
   * Replace the block in the list with the given block
   * @param {*} newBlock The new block
   */
  async updateBlock(newBlock) {
    let block = this.findBlock(newBlock.id);
    const blockIndex = this.blocks.findIndex((b) => b.id === block.id);
    // Combine the new block with the old block (So we have a PUT behaviour)
    block = { ...block, ...newBlock };
    try {
      const response = await updateBlock(block);
      // TODO: Ask jonas if this can be done any better
      this.blocks[blockIndex].validFrom = block.validFrom;
      this.blocks[blockIndex].validTo = block.validTo;
      EditorEventBus.$emit(
        'notify',
        i18n.t('notifications.updateBlockSuccess'),
        'success'
      );
    } catch (_) {
      EditorEventBus.$emit(
        'notify',
        i18n.t('notifications.updateBlockError'),
        'error'
      );
    }
  }

  /**
   * Create a new block below the given parent
   * @param {*} newBlock The block to add to the list
   */
  createBlock(newBlock) {
    const parentBlockIndex = this.blocks.findIndex(
      (block) => block.id === newBlock.parent
    );

    const parentBlock = this.blocks[parentBlockIndex];
    // The block is added as a children from the parent
    newBlock.depth = parentBlock.depth + 1;
    newBlock.parent = parentBlock.id;
    parentBlock.children.push(newBlock);

    let newBlockIndex = parentBlockIndex;

    // Search for the lastIndex in the block (with the help of the depth)
    while (true) {
      newBlockIndex++;

      // Break the loop if we are at the end of the list
      if (newBlockIndex >= this.blocks.length) {
        break;
      }

      // If the next block has a depth that is equals or lower of the new block parents depth, break the loop
      if (this.blocks[newBlockIndex].depth <= parentBlock.depth) {
        break;
      }
    }

    this.blocks.splice(newBlockIndex, 0, newBlock);
    // Reload all blocks
    this.blocks = flattenTheTree(createTreeFromList(this.blocks)[0]);
  }

  /**
   * Repeal the given block with all his childrens
   * @param {*} blockId The id from the block to repeal
   */
  async repealBlock(blockId) {
    const repealedBlock = await repealBlock(blockId);

    const selectedBlockIndex = this.blocks.findIndex(
      (b) => b.id === repealedBlock.id
    );
    const selectedBlock = this.blocks[selectedBlockIndex];

    this.blocks.splice(selectedBlockIndex, 1, {
      ...selectedBlock,
      ...repealedBlock,
    });

    if (selectedBlock.children.length > 0) {
      const repealChildren = (id) => {
        this.blocks.forEach((block) => {
          if (!block.isRepealed && block.parent === id) {
            repealChildren(block.id);
            block.text = '...';
            block.isRepealed = true;
          }
        });
      };

      repealChildren(blockId);
    }
  }

  /**
   * Delete the given block with all his childrens
   * @param {*} blockId THe id from the block to delete
   */
  deleteBlock(blockId) {
    const deletedBlock = this.findBlock(blockId);
    if (!deletedBlock.isDeleted) {
      this.softDeleteBlock(deletedBlock);
      return;
    }
    const deletedBlockIndex = this.blocks.findIndex((b) => b.id === blockId);
    let deleteCount = 1;

    // If the deleted block has children, we have to delete those as well.
    if (this.blocks[deletedBlockIndex].children.length > 0) {
      const deleteChildren = (id) => {
        this.blocks.forEach((block) => {
          if (block.parent === id) {
            deleteChildren(block.id);
            deleteCount += 1;
          }
        });
      };

      deleteChildren(blockId);
    }

    const parent = this.findBlock(deletedBlock.parent);

    const deletedBlockIndexInParent = parent.children.findIndex(
      (b) => b.id === blockId
    );

    parent.children.splice(deletedBlockIndexInParent, 1);

    this.blocks.splice(deletedBlockIndex, deleteCount);
  }

  softDeleteBlock(block) {
    // If the soft deleted block has children, we have to soft delete those as well.
    if (block.children.length > 0) {
      const softDeleteChildren = (id) => {
        this.blocks.forEach((block) => {
          if (block.parent === id) {
            softDeleteChildren(block.id);
            block.isDeleted = true;
          }
        });
      };

      softDeleteChildren(block.id);
    }

    block.isDeleted = true;
  }

  restoreBlock(blockId) {
    const blockToRestore = this.findBlock(blockId);

    if (blockToRestore.children.length > 0) {
      const restoreChildren = (id) => {
        this.blocks.forEach((block) => {
          if (block.parent === id) {
            restoreChildren(block.id);
            block.isDeleted = false;
          }
        });
      };

      restoreChildren(blockId);
    }

    blockToRestore.isDeleted = false;
  }

  getBlocks() {
    return this.blocks;
  }

  getTableOfContents() {
    // Get all blocks that match the table of content types
    const tableOfContents = this.blocks.reduce((arr, block, index) => {
      if (
        TABLE_OF_CONTENTS_TYPES.includes(block.htmlElementReference) &&
        index !== 0
      ) {
        arr.push({
          ...block,
          index,
        });
      }
      return arr;
    }, []);
    return tableOfContents;
  }

  moveBlock(response) {
    // Remove block at old position so that it is not regarded when looking for index
    for (let i = 0; i < response.block.length; i++) {
      const block = response.block[i];
      const blockIndex = this.blocks.findIndex(
        (oldBlock) => oldBlock.id === block.id
      );
      this.blocks.splice(blockIndex, 1);
    }

    // Reload all blocks and get parent block
    this.blocks = flattenTheTree(createTreeFromList(this.blocks)[0]);
    const parentBlock = this.findBlock(response.parent);
    const parentBlockIndex = this.blocks.findIndex(
      (block) => block.id === response.parent
    );

    // Used to calculate the index position where the blocks should be added
    let indexToAdd = 0;

    // Iterates through the childrens and adds the children length to the indexToAdd
    const checkChildrens = (block) => {
      if (block.children) {
        block.children.forEach((child) => {
          checkChildrens(child);
          indexToAdd++;
        });
      }
    };

    // Increase the index for every block before the new position
    for (let i = 0; i < response.index; i++) {
      const child = parentBlock.children[i];

      checkChildrens(child);
      indexToAdd++;
    }

    for (let i = 0; i < response.block.length; i++) {
      const block = response.block[i];

      // If it is the first block we need to use the depth of the parent + 1
      if (i === 0) {
        block.depth = parentBlock.depth + 1;
      } else {
        // Otherwise this block is a children of the last block so we use the depth of the last block + 1
        block.depth = response.block[i - 1].depth + 1;
      }

      const newIndex = parentBlockIndex + indexToAdd + i + 1;
      // Insert block at new position
      this.blocks.splice(newIndex, 0, block);
    }

    // Reload all blocks
    this.blocks = flattenTheTree(createTreeFromList(this.blocks)[0]);
  }
}
