<template>
  <div
    id="blocks-parent"
    class="
      max-h-[90vh]
      overflow-y-auto overflow-x-visible
      break-words
      pr-16
      relative
    "
  />
</template>
<script>
import UserEventBus from '@/helpers/UserEventBus';
import { render } from '@/components/user/DocumentBlock';
import { getDocumentLinks } from '@/services/document';
import { replaceLinksToHTMLLinks } from '@/helpers/links';

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentHeading: null,
      lastHeadings: [],
      allHeadings: [],
      skipNextScroll: false,
      currentLinksBlock: null,
      isMenuOpen: false,
    };
  },
  async mounted() {
    this.allHeadings = this.blocks.filter((block) => this.isStickyBlock(block));

    const id = this.$route.params.blockId;

    if (id) {
      let idNumber = parseInt(id);
      const block = this.blocks.find((block) => block.id === idNumber);
      setTimeout(() => {
        this.scrollTo(block);
      }, 1);
    }
    this.$nextTick(() => {
      this.render();
    });

    const links = await getDocumentLinks(
      this.document.id,
      localStorage.getItem('lang'),
      true,
      true
    );
    this.blocks.forEach((block) => {
      if (block.id in links) {
        block.links = links[block.id];
      }
    });
    this.renderLinks();
    const allLinks = await getDocumentLinks(
      this.document.id,
      localStorage.getItem('lang'),
      true
    );
    this.blocks.forEach((block) => {
      if (block.id in allLinks) {
        block.links = allLinks[block.id];
      }
    });
    this.renderLinks();
  },
  beforeDestroy() {
    document
      .getElementById('blocks-parent')
      .removeEventListener('scroll', this.onScroll);
  },
  methods: {
    scrollTo(block) {
      this.skipNextScroll = true;

      const blockIndex = this.blocks.findIndex((b) => b.id === block.id);

      const nextBlock = this.blocks[blockIndex + 1];

      const element = document.querySelector(
        '[data-block-id="' + (nextBlock ? nextBlock.id : block.id) + '"]'
      );

      document
        .getElementById('blocks-parent')
        .scrollTo({ top: element.offsetTop });

      this.setCurrentHeading(block.id);
    },
    onScroll() {
      // The scroll should be skipped if the user scrolled with the table of contents
      if (this.skipNextScroll) {
        this.skipNextScroll = false;
        return;
      }
      const stickyBlocks = document.querySelectorAll('[sticky]');
      this.handleStickyScrolling(stickyBlocks);
    },
    handleStickyScrolling(stickyChildrens) {
      const parentTop = document
        .getElementById('blocks-parent')
        .getBoundingClientRect().top;

      for (let i = 0; i < stickyChildrens.length; i++) {
        // Access DocumentBlock instance
        const el = stickyChildrens[i];
        const boundingClientRect = el.getBoundingClientRect();

        // Check if elem top - top of the parent is lower than 0
        const isScrolledBy = () => boundingClientRect.top - parentTop < 0;

        // Check if elem top - top of the parent is greather than 0
        const isNextNotScrolledBy = () => {
          const nextChildrenEl = stickyChildrens[i + 1];
          const nextBoundingClientRect = nextChildrenEl.getBoundingClientRect();
          return nextBoundingClientRect.top - parentTop > 0;
        };
        const blockId = el.getAttribute('data-block-id');
        // The last sticky children has not next element so the condition is a little bit different
        if (i === stickyChildrens.length - 1) {
          // If the current element is "scrolled by" set is as current heading
          if (isScrolledBy() && this.currentHeading?.id != blockId) {
            this.setCurrentHeading(el.getAttribute('data-block-id'));
          }

          continue;
        }

        // If the current element is "scrolled by" and the next one is not set is as current heading
        if (
          isScrolledBy() &&
          isNextNotScrolledBy() &&
          blockId != this.currentHeading?.id
        ) {
          this.setCurrentHeading(el.getAttribute('data-block-id'));

          break;
        } else if (
          i === 0 &&
          boundingClientRect.top - parentTop > 0 &&
          this.currentHeading?.id == blockId &&
          this.lastHeadings
        ) {
          // This is used for scrolling up in the content. Its needed because sometimes we only have one heading currently rendered.
          // The virtual list is a pain with this sticky functionality. This pop gets the current heading without the heading needing to be rendered.
          this.currentHeading = this.lastHeadings.pop();
          this.$emit('changeHeading', this.currentHeading);
        }
      }
    },
    setCurrentHeading(blockId) {
      const block = this.blocks.find((block) => block.id == blockId);
      this.currentHeading = block;

      this.$emit('changeHeading', block);

      const currentHeadingIndex = this.allHeadings.findIndex(
        (heading) => heading.id === this.currentHeading.id
      );
      // Find the index of the last heading. There is a fallback to -1 if there was any error
      const lastHeadingIndex =
        this.lastHeadings.length > 0
          ? this.allHeadings.findIndex(
              (heading) =>
                heading.id ===
                this.lastHeadings[this.lastHeadings.length - 1].id
            )
          : -1;

      // This is a fallback if some blocks got skipped while scrolling.
      // If there are any headings between the currentHeading and the last one add them to the array
      if (currentHeadingIndex - 1 > lastHeadingIndex) {
        for (let i = lastHeadingIndex; i < currentHeadingIndex - 1; i++) {
          this.lastHeadings.push(this.allHeadings[i]);
        }
      }

      // This is the fallback in the scroll up direction. We want to remove all blocks that got skipped
      if (currentHeadingIndex < lastHeadingIndex) {
        for (let i = currentHeadingIndex; i < lastHeadingIndex; i++) {
          this.lastHeadings.pop();
        }
      }

      // If this heading is not already in the lastHeading list push it. Otherweise pop the lastHeading list because we scrolled upwards.
      if (
        !this.lastHeadings.some(
          (heading) => heading?.id === this.currentHeading.id
        )
      ) {
        this.lastHeadings.push(this.currentHeading);
      } else {
        this.lastHeadings.pop();
      }
    },
    isStickyBlock(block) {
      switch (block.htmlElementReference) {
        case 'heading_2':
        case 'heading_3':
        case 'part':
        case 'chapter':
        case 'section':
        case 'heading_4':
        case 'heading_5':
        case 'heading_6':
        case 'article':
        case 'title':
          return true;
        default:
          return false;
      }
    },
    handleSetCurrentBlock(block) {
      if (this.currentLinksBlock == block) {
        this.currentLinksBlock = null;
      } else {
        this.currentLinksBlock = block;
      }

      UserEventBus.$emit('setCurrentBlock', this.currentLinksBlock);
    },
    render() {
      const fragment = document.createDocumentFragment();

      for (let i = 0; i < this.blocks.length; i++) {
        this.renderBlock(this.blocks[i], fragment, i);
      }

      document.getElementById('blocks-parent').appendChild(fragment);

      window.requestAnimationFrame(() => {
        document
          .getElementById('blocks-parent')
          .addEventListener('scroll', this.onScroll);
      });
    },
    renderBlock(block, fragment, index) {
      render(block, fragment, index === this.blocks.length - 1);
    },
    renderLinks() {
      this.blocks
        // only update blocks which contain links
        .filter((block) => block.links?.length)
        .forEach((block) => {
          const blockElem = document.querySelector(
            `[data-block-id="${block.id}"]`
          );

          if (
            block.links.filter((link) => !link.inPlace).length &&
            !blockElem.querySelector('img') // avoid creating links on image blocks
          ) {
            // create new button for links
            const linkButton = document.createElement('button');
            linkButton.classList.add(
              'p-2',
              'block-link--margin',
              'rounded-r',
              'h-[40px]',
              'bg-secondary-600'
            );
            linkButton.setAttribute('links-button', true);

            // load icon into button
            linkButton.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.787 2.25h-.006c-.458.01-.896.194-1.225.513l-.29.29-.002.002a1.799 1.799 0 0 0-.493 1.342L8.587 6.074l-.002.002c-.9.715-1.716 1.53-2.431 2.43l-1.73 2.264a1.77 1.77 0 0 0-1.37.495l-.29.289v.002c-.32.33-.503.767-.514 1.225v.006c.006.453.192.886.515 1.203l3.556 3.556a1.692 1.692 0 0 0 2.438 0l.285-.286h.001a1.72 1.72 0 0 0 .515-1.203v-.002a.789.789 0 0 0-.015-.142v-.002l1.708-1.33 6.105 6.364c.471.497 1.12.787 1.805.805h.03a2.557 2.557 0 0 0 2.557-2.584v-.003a2.583 2.583 0 0 0-.805-1.805l-6.364-6.08 1.338-1.744h.137a1.694 1.694 0 0 0 1.204-.515l.286-.286a1.693 1.693 0 0 0 0-2.438h-.001l-3.555-3.53a1.72 1.72 0 0 0-1.203-.515Zm-.145 1.37a.295.295 0 0 1 .134-.024h.007a.373.373 0 0 1 .26.112l.002.001L16.6 7.266a.4.4 0 0 1 0 .549l-.289.288-.002.003a.347.347 0 0 1-.511 0l-.002-.003-3.558-3.558a.425.425 0 0 1-.025-.551l.313-.287.006-.007a.294.294 0 0 1 .11-.08ZM9.424 7.147l1.988-1.54 3.292 3.318-1.54 1.988c-.631.811-1.36 1.54-2.172 2.17L8.9 14.705l-3.267-3.292 1.594-2.066a12.617 12.617 0 0 1 2.197-2.198ZM4.126 12.15c.049-.019.1-.028.152-.026h.001a.478.478 0 0 1 .272.094l3.554 3.58.005.005a.295.295 0 0 1 .104.243v.006a.373.373 0 0 1-.112.262h-.001l-.286.287a.4.4 0 0 1-.55 0L3.71 13.045v-.001a.373.373 0 0 1-.113-.261v-.007a.295.295 0 0 1 .104-.243l.005-.004.292-.292.001-.001a.373.373 0 0 1 .128-.086Zm8.195 1.586c.501-.441.974-.914 1.415-1.415l3.02 2.873-1.562 1.561-2.873-3.018Zm3.812 3.995 1.598-1.598 2.3 2.2c.229.22.362.52.373.835-.007.328-.13.643-.349.887a1.367 1.367 0 0 1-.887.349 1.21 1.21 0 0 1-.834-.373l-2.2-2.3Z" fill="#fff"/>
            </svg>`;

            // open links when button is pressed
            linkButton.addEventListener('click', () => {
              this.handleButtonClick(linkButton, block);
              this.handleSetCurrentBlock(block);
            });

            // insert button into DOM
            blockElem.firstElementChild.prepend(linkButton);

            // update block element classes
            blockElem.classList.remove('pl-24', 'xl:pl-32');
            blockElem.classList.add('min-h-[48px]', 'align-top');
          }

          const blockTextElem = blockElem.querySelector('span');
          blockTextElem.innerHTML = replaceLinksToHTMLLinks(
            block.text,
            block.links
          );
        });
      this.$emit('finishedLoading');
    },
    handleButtonClick(button, block) {
      // Reset all selected blocks
      document
        .querySelectorAll('.block--selected')
        .forEach((element) => element.classList.remove('block--selected'));

      if (this.currentLinksBlock !== block) {
        // Add selected class to block
        document
          .querySelector("[data-block-id='" + block.id + "']")
          .classList.add('block--selected');

        // Add selected class to all childrens
        const walkChildren = (children) => {
          document
            .querySelector("[data-block-id='" + children.id + "']")
            .classList.add('block--selected');

          children.children.forEach((child) => {
            walkChildren(child);
          });
        };
        walkChildren(block);

        // If there is already an activated links button reset it
        const activatedButton = document.querySelector(
          '.bg-secondary-800[links-button]'
        );
        if (activatedButton) {
          activatedButton.classList.remove('bg-secondary-800');
          activatedButton.classList.add('bg-secondary-600');
        }
      }

      // Update the background color
      if (button.classList.contains('bg-secondary-600')) {
        button.classList.remove('bg-secondary-600');
        button.classList.add('bg-secondary-800');
      } else {
        button.classList.remove('bg-secondary-800');
        button.classList.add('bg-secondary-600');
      }
    },
  },
};
</script>

<style lang="postcss">
.frontend .article {
  @apply text-secondary-600;
}

/** This one is special because we want to start the background after the "link button". This is possible with a gradient that has a left offset of 110px */
.block--selected {
  @screen xl {
    background: linear-gradient(to left, #cceced, #cceced) no-repeat 110px 0px;
  }

  background: linear-gradient(to left, #cceced, #cceced) no-repeat 70px 0px;
}

.block-link--margin {
  @apply mr-[56px] xl:mr-[88px];
}
</style>
