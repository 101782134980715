import { replaceLinksToHTMLLinks } from '@/helpers/links';

export default {
  computed: {
    text() {
      let text = this.source.text;

      text = replaceLinksToHTMLLinks(text, this.source.links);

      return text;
    },
    blockLinks() {
      return this.source.links.filter((link) => !link.inPlace);
    },
    isImageBlock() {
      return this.source.htmlElementReference === 'image';
    },
    isTableBlock() {
      return this.source.htmlElementReference === 'table';
    },
    isLetterBlock() {
      return this.source.htmlElementReference === 'letter';
    },
    isDigitBlock() {
      return this.source.htmlElementReference === 'digit';
    },
    isListBlock() {
      return this.source.htmlElementReference === 'list';
    },
    isNumberedParagraph() {
      return this.source.htmlElementReference === 'num_paragraph';
    },
    blockStyles() {
      if (this.isLetterBlock || this.isDigitBlock || this.isListBlock) {
        return { 'margin-left': 24 * this.source.listDeepness + 'px' };
      }
      return {};
    },
  },
};
