import { userApi } from './api';

export const updateBlock = async (payload) => {
  return await userApi.put(`block/${payload.id}/`, payload);
};

export const getHTMLElementReferences = async () => {
  return await userApi.get(`element-reference/`);
};

export const getBlockCategories = async () => {
  return (await userApi.get(`category/`)).data;
};

export const createBlock = async (payload) => {
  let response;
  if (payload.htmlElementReference === 'image') {
    const formData = new FormData();
    formData.append('image', payload.image, payload.image.name);
    formData.append('parent', payload.parent);
    formData.append('htmlElementReference', 'image');
    response = await userApi.post('block/', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  } else {
    response = await userApi.post('block/', payload);
  }
  return response.data;
};

export const moveBlock = async (id, payload) => {
  const response = await userApi.post(`block/${id}/move/`, payload);
  return response.data;
};

export const repealBlock = async (id) => {
  const response = await userApi.patch(`block/${id}/repeal/`);
  return response.data;
};

export const uploadImage = async (blockId, file) => {
  const formData = new FormData();
  formData.append('image', file, file.name);
  const response = await userApi.post(`block/${blockId}/image/add/`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
};

export const removeImage = async (blockId, image) => {
  const response = await userApi.delete(`block/${blockId}/image/delete/`, {
    data: { image },
  });
  return response.data;
};
