<template>
  <div class="flex justify-end mb-3">
    <a
      :href="document.externalLink"
      :disabled="!document.externalLink"
      class="px-2 mr-4"
      target="_blank"
    >
      <svg-icon name="link-external" class="svg-icon--lg" />
    </a>
    <a class="px-2 mr-4" :href="pdfUrl" target="_blank">
      <svg-icon name="pdf" class="svg-icon--lg" />
    </a>
    <button :class="favoriteClasses" @click="changeFavoriteStatus">
      <svg-icon name="favorite" class="svg-icon--lg" />
    </button>
  </div>
</template>

<script>
import { addFavorite, deleteFavorite } from '@/services/Favorite.service';
import { mapState } from 'vuex';

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      favoriteId: null,
    };
  },
  computed: {
    ...mapState('Favorite', ['favorites']),
    pdfUrl() {
      return `${process.env.VUE_APP_MEDIA_URL}${this.document.pdf}`;
    },
    favorite() {
      return this.favorites[this.document.retrievedLanguage].find(
        (f) => f.document && f.document.id === this.document.id
      );
    },
    favoriteClasses() {
      let classes = 'px-2 favorite';
      if (this.favorite) {
        classes += ' is-active shadow-inner-frontend';
      }
      return classes;
    },
  },
  methods: {
    async changeFavoriteStatus() {
      if (!this.favorite) {
        await addFavorite({
          document: this.document.id,
        });
      } else {
        await deleteFavorite(this.favorite.id);
      }
      await this.$store.dispatch('Favorite/loadFavorites');
    },
  },
};
</script>
